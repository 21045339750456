import Moment from 'moment/moment';

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const isStrosslerEmail = (email) => {
  if (!email || typeof email !== 'string') {
    return false;
  }
  return (
    email.endsWith('@strossle.com') ||
    email.endsWith('@azerion.com') ||
    email.endsWith('@ext.azerion.com')
  );
};

// Function to handle b-table-simple column sorting
export const setSortColumn = (column, columns, order = '') => {
  let currentColumn = null;
  let currentDirection = 'DESC';
  Object.keys(columns).forEach((k) => {
    // Neutralize all other columns
    if (k !== column) {
      columns[k] = 'none';
      return;
    }
    // This is used then a specific order is wanted, e.g. from query param
    if (order) {
      switch (order) {
        case 'desc':
          columns[k] = 'descending';
          currentColumn = k.toUpperCase();
          currentDirection = 'DESC';
          break;
        case 'asc':
          columns[k] = 'ascending';
          currentColumn = k.toUpperCase();
          currentDirection = 'ASC';
          break;
      }
      return { currentColumn, currentDirection };
    }
    // Sort relevant column here
    switch (columns[k]) {
      case 'none':
        columns[k] = 'ascending';
        currentColumn = k.toUpperCase();
        currentDirection = 'ASC';
        break;
      case 'ascending':
        columns[k] = 'descending';
        currentColumn = k.toUpperCase();
        currentDirection = 'DESC';
        break;
      case 'descending':
        columns[k] = 'none';
        break;
    }
  });
  return { currentColumn, currentDirection };
};

const recursiveSort = (parentArr, key, result) => {
  if (parentArr[key] == undefined) return;

  let arr = parentArr[key];
  for (let i = 0; i < arr.length; i++) {
    result.push(arr[i]);
    recursiveSort(parentArr, arr[i].id, result);
  }

  return result;
};

const groupBy = (items, key) =>
  items.reduce(function (rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});

export const hierarchySort = (arr) => {
  const groupedParentReferences = groupBy(arr, 'parentId');
  let topNodeId = null;
  for (let node of arr) {
    if (!arr.some((el) => el.id === node.parentId)) {
      topNodeId = node.parentId;
      break;
    }
  }
  if (!topNodeId) {
    return arr;
  }
  return recursiveSort(groupedParentReferences, topNodeId, []);
};

export const twoDecimalsFormatter = (price) => {
  let input = price;

  // Replace , with .
  if ((input.match(/,/g) || []).length) {
    input = input.replace(',', '.');
  }

  // Only allow digits and dots
  input = (input.match(/\d|\./g) || []).join('');

  // Only accept one dot
  if ((input.match(/\./g) || []).length > 1) {
    const split = input.split('.');
    // Just use the first dot
    input = split.shift() + '.' + split.join('');
  }

  return input;
};

export const getBaseDomain = (domain, isValidating = false) => {
  // Must contain at least two characters after a period
  if (!domain || !domain.match(/\.[a-z]{2,}$/gi) || domain.endsWith('.')) {
    return isValidating ? false : domain;
  }
  // Convert the domain to lowercase and remove duplicate or more consecutive periods
  let hostname = domain.toLowerCase().replace(/\.+/g, '.');
  try {
    const url = new URL(domain);
    hostname = url.hostname;
  } catch (ex) {
    // Not a valid domain
  }
  const s = hostname.split('.');
  if (s.length === 2) {
    // OK domain
    return isValidating ? true : hostname;
  }
  if (s.length > 2) {
    // Domain contains subdomain or a .co.xx toplevel
    if (s[s.length - 2] === 'co') {
      // co.xx domain
      return isValidating ? true : s.slice(-3).join('.');
    }
    return isValidating ? true : s.slice(-2).join('.');
  }
  return isValidating ? false : domain;
};

export const normalizeDateRange = ({ startDate, endDate }) => {
  const start = Moment(startDate);
  const end = Moment(endDate);

  // ignoring local browser TZ (truncating)
  start.add(start.utcOffset(), 'minutes').utc();
  end.add(end.utcOffset(), 'minutes').utc();
  start.startOf('day');
  end.endOf('day');

  return { startDate: start, endDate: end };
};

export const shallDisplayEcpmColumn = (groupResponseObject) => {
  return (
    Object.values(groupResponseObject).filter(
      (item) => item?.reportSettings?.ecpm === true
    ).length > 0
  );
};

export const isStrossler = (email) => {
  return isStrosslerEmail(email);
};

export const removeTypename = (key, value) =>
  key === '__typename' ? undefined : value;

export const removeTypenames = (obj) =>
  JSON.parse(JSON.stringify(obj), removeTypename);

export const AD_STATUSES = {
  REJECTED: [
    'REJECTED_REVIEW_WILL_BE_INACTIVE',
    'REJECTED_REVIEW_WILL_BE_ACTIVE',
  ],
  PENDING: ['PENDING_REVIEW_WILL_BE_INACTIVE', 'PENDING_REVIEW_WILL_BE_ACTIVE'],
  INACTIVE: [
    'INACTIVE',
    'PENDING_REVIEW_WILL_BE_INACTIVE',
    'REJECTED_REVIEW_WILL_BE_INACTIVE',
  ],
  BLOCK_ACTIVATION: [
    'REJECTED_REVIEW_WILL_BE_INACTIVE',
    'REJECTED_REVIEW_WILL_BE_ACTIVE',
    'PENDING_REVIEW_WILL_BE_INACTIVE',
    'PENDING_REVIEW_WILL_BE_ACTIVE',
  ],
};
